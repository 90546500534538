import React from "react"
import { Helmet } from "react-helmet"
import config from "../../site.config"

/** components */
import Layout from "../components/Layout"
import { theme } from "../components/Theme"

/** dynamic sections */
import HeroTopSection from "../components/dynamic-sections/HeroTopSection"
import RibbonSection from "../components/dynamic-sections/RibbonSection"
import HeroRightSection from "../components/dynamic-sections/HeroRightSection"
import AwardsSection from "../components/dynamic-sections/AwardsSection"

/** svg */
import NorhartIcon from "../../assets/norhart-icon.svg"
import ApartmentIcon from "../../assets/apartment-icon.svg"
import NorhartDarkCircleIcon from "../../assets/norhart-dark-circle-icon.svg"

/** color theme */
const colorPalette = theme.colorPalettes.blue

/** props */
interface Props {
  data: any
}

/** const */
const MediaPage: React.FC<Props> = (props) => {
  const ldJson = {
    "@context": "http://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: 1,
        item: {
          "@id": "/media/",
          name: "Media | Norhart",
          image: `${config.siteMetadata.siteUrl}/media/norhart-media-hero-open-graph.png`,
        },
      },
    ],
  }

  return (
    <Layout
      title="Media | Norhart"
      description=""
      keywords="norhart, media, pr, news,"
      imageTwitter={`${config.siteMetadata.siteUrl}/media/norhart-media-hero-twitter-card.png`}
      imageOpenGraph={`${config.siteMetadata.siteUrl}/media/norhart-media-hero-open-graph.png`}
      colorPalette={colorPalette}
    >
      <HeroTopSection
        title="Media"
        subtitle="Insert Subtitle"
        imageTitle="Norhart Media"
        image="/media/norhart-media-hero.png"
        colorPalette={colorPalette}
      />

      <RibbonSection title="Media" tagLine="Insert Media" colorPalette={colorPalette} />

      <Helmet>
        <script type="application/ld+json">{JSON.stringify(ldJson)}</script>
      </Helmet>

      <AwardsSection
        header="Norhart Apartments"
        title="What Drives Us"
        subtitle="Our culture and values propel us forward. Always be respectful, results drive success, we love to cultivate innovation, and above all, we succeed together. We fail together. That's how we roll!"
        colorPalette={colorPalette}
      />
    </Layout>
  )
}

/** export */
/** export */
export default MediaPage
